import useSWRImmutable from 'swr/immutable';
import { getConfig } from '../utils/config';
import { useAppSelector } from '../redux-tool-kit/hooks';
import { selectJwt } from '../redux-tool-kit/userSlice';
import { edgeGetFetcher } from '../services';

const useLocationBranch = (branchNumber: string | null) => {
  const jwt = useAppSelector(selectJwt);
  const cleanedBranch = cleanBranchNumber(branchNumber);
  const urlStr = cleanedBranch ? `${getConfig().getBranches}&userGroupBranch=${cleanedBranch}` : null;
  const fetcher = ([url, jwt]: [string, string | null]) => edgeGetFetcher(url, jwt, '').then((states) => states);

  return useSWRImmutable(urlStr ? [urlStr, jwt] : null, fetcher);
};

export const useBranchPsid = (legacyGb: string | null): string | null => {
  const { data } = useLocationBranch(legacyGb);
  return data?.[0]?.peoplesoftId ?? null;
};

const cleanBranchNumber = (branchNumber: string | null) => {
  if (!branchNumber) return null;

  const branch = branchNumber.trim();
  // need to encode this because legacy branches can have special chars
  return branch.length === 4 ? encodeURIComponent(branch) : null;
};

export default useLocationBranch;
