import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../types';
import type { RootState } from './store';

// Define the initial state using that type
export const initialState: UserState = {
  jwt: null,
  jwtData: null,
  firstName: null,
  lastName: null,
  eid: null,
  groupBranch: null,
  loggedIn: false,
  deviceId: null,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Example of simple state update
    // updateSomethingNumber: (state) => {
    //   state.somethingNumber += 1;
    // },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<object>) => {
      const newState: UserState = {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
      return newState;
    },
  },
});

export const { setUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const userData = (state: RootState) => state.user;
export const selectJwt = (state: RootState) => state.user.jwt;
export const selectGroupBranch = (state: RootState) => state.user.groupBranch;
export default userSlice.reducer;
