import axios from 'axios';
import useSWRImmutable from 'swr/immutable';
import { edgeGetFetcher } from '..';
import { useAppSelector } from '../../redux-tool-kit/hooks';
import { store } from '../../redux-tool-kit/store';
import { selectJwt } from '../../redux-tool-kit/userSlice';
import { getConfig } from '../../utils/config';

export interface PrintQueueItem {
  id: string;
  type: string;
  locationDescription: string;
  printQueueDescription: string;
  model: string;
  location: string;
}

export interface GetPDFBytesResponse {
  success: boolean;
  data?: string;
  error?: string;
}

export interface PDFPrintResponse {
  success: boolean;
  error?: string;
}

const fetcher = ([url, jwt]: [string, string | null]) =>
  edgeGetFetcher(url, jwt, 'errors.getPrintersError', {
    'Ehi-Origin-Identity': 'CCAP',
  });

export const useAvailablePrinters = (branchId: string | null) => {
  const jwt = useAppSelector(selectJwt);
  return useSWRImmutable<PrintQueueItem[]>(
    jwt && branchId && [`${getConfig().printQueue}&locationBranchId=${branchId}`, jwt],
    fetcher
  );
};

export const getPrintablePDF = async (claimId: number, unitLocation: string | null): Promise<GetPDFBytesResponse> => {
  const user = store.getState().user;
  try {
    const response: any = await axios.get(
      `${getConfig().superedge}${
        getConfig().getPrintablePDF
      }&claimId=${claimId}&pdfName=${claimId.toString()}&pdfDate=`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: `Bearer ${user.jwt}`,
          'condition-unit-location': unitLocation,
          'ehi-locale': 'eng-USA',
          'ehi-caller-id': 'CCAP',
        },
      }
    );

    if (response.data.printablePdfbytes) {
      return { success: true, data: response.data.printablePdfbytes };
    } else {
      return { success: false, error: response.data.errorMessages };
    }
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const printDocument = async (
  queueId: string,
  dataBytes: string,
  deviceLocation: string
): Promise<PDFPrintResponse> => {
  const user = store.getState().user;
  try {
    const response: any = await axios.post(
      `${getConfig().superedge}${getConfig().printDocument}`,
      {
        printQueueId: queueId,
        printData: dataBytes,
      },
      {
        headers: {
          'Ehi-Device-Location-Id': deviceLocation,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${user.jwt}`,
        },
      }
    );

    if (response.data.success) {
      return { success: true };
    } else {
      return { success: false, error: response.data.errorMessages };
    }
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
